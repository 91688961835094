<template>
  <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  <div class="login-box">
    <div class="title">欢迎登录在线备课系统</div>
    <div class="wx">
      <iframe
        height="100%"
        sandbox="allow-scripts allow-top-navigation allow-same-origin"
        src="http://zy.yunlearning.com:8083/api/wxlogin"
        frameborder="0"
      ></iframe>
    </div>
    <div class="choose">
      <div class="right">
        <router-link to="/login/"><span>密码登录</span></router-link> |
        <router-link to="/login/register"><span>注册账号</span></router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import $ from "jquery";

export default {
  name: "Login",
  data() {
    return {
      checked: false,
    };
  },
  mounted() {},
  methods: {
    towxlink() {
      this.$router.push("/login/wxlink");
    },
  },
  components: {
    // HelloWorld,
  },
};
</script>
<style lang="less" scoped>
.login-box {
  width: 630/96in;
  height: 738/96in;
  background: url("../../img/登录_slices/组3.png") no-repeat;
  position: fixed;
  top: 50%;
  transform: translateY(-49%);
  right: 111px;
  text-align: center;
  padding: 108/96in 90/96in 80/96in;
  .title {
    font-size: 30/96in;
    font-weight: bold;
    color: #121212;
    height: 30/96in;
    line-height: 30/96in;
    letter-spacing: 0.1em;
    margin-bottom: 63/96in;
  }
  .wx {
    margin-bottom: 10/96in;
    height: 395/96in;
  }
  /deep/.wrp_code img {
    width: 280px !important;
  }
  .text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .choose {
    // margin-top: 74 /96in;
    font-size: 14/96in;
    font-weight: 400;
    color: #313339;
    line-height: 63/96in;
  }
}
</style>
